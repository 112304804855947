.footer {
    background-color: #333;
    color: #fff;
    padding: 50px 0;
  }
  
  .footer-content {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }
  
  .footer-section {
    margin-bottom: 30px;
  }
  
  .footer-section h3 {
    margin-bottom: 10px;
  }
  
  .social-icons {
    display: flex;
  }
  
  .social-icon {
    font-size: 20px;
    margin-right: 10px;
    color: #fff;
    transition: color 0.3s;
  }
  
  .social-icon:hover {
    color: #007bff;
  }
  
  .footer-bottom {
    text-align: center;
    margin-top: 30px;
  }
  
  .footer-bottom p {
    margin: 10px 0;
  }
  
  .footer-bottom a {
    color: #55f6c8;
  }
  

  @media(max-width:800px){
    .footer {
        padding: 10px;
        padding-top: 20px;
      }
      
  }