.location-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 50px;
    background: rgba(190, 190, 190, 0.099);
    padding: 20px;
  }
  
  .location-icon {
    font-size: 36px;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .location-icon svg{
     margin: 10px;
  }

  .location-icon h2{
    font-size: 1.2rem;
  }
  
  .location-details {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .map-container {
    width: 100%;
    max-width: 800px;
  }
  
  iframe {
    width: 100%;
    height: 450px;
  }
  

  @media(max-width:900px){
    .location-section {
        padding: 10px;

    }
  }