.contact-container {
    padding: 50px;
  }

  .contact-container h2{
    margin-bottom: 15px;
  }
  
  .contact-content {
    display: flex;
    flex-direction: column;
  }
  
  .contact-form {
    margin-bottom: 30px;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  .label {
    font-weight: bold;
  }
  
  input[type='text'],
  input[type='email'],
  textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
  }
  
  button {
    background-color: #007bff;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .map {
    width: 100%;
    height: 300px;
  }
  
  @media (min-width: 768px) {
    .contact-content {
      flex-direction: row;
    }
  
    .contact-form {
      margin-right: 30px;
      width: 50%;
    }
  
    .map-container {
      width: 50%;
    }
  }
  