.faculty-section {
    text-align: center;
    margin: 50px auto;
    background: #f9f9f9;
    padding-top: 40px;
  }
  
  .faculty-section h2 {
    font-size: 32px;
    margin-bottom: 20px;
  }
  
  .carousel-wrapper {
    max-width: 800px;
    margin: 0 auto;
  }
  
  .faculty-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }
  
  .faculty-image {
    max-width: 350px; /* Adjust image width as needed */
    height: 360px;
    border-radius: 50%;
    margin-bottom: 40px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s;
  }
  
  .faculty-image:hover {
    transform: translateY(-5px);
  }
  
  .faculty-details {
    text-align: center;
  }
  
  .faculty-details h3 {
    margin-top: 0;
  }
  
  .faculty-details p {
    margin: 10px 0;
  }
  