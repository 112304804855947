.admin-dashboard {
  margin: 20px;
}

.cards {
  display: flex;
  flex-wrap: wrap;
}

.card {
  background-color: #ffffff;
  border-radius: 10px;
  padding: 20px;
  margin: 10px;
  text-decoration: none;
  color: #000;
  transition: all 0.3s ease;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  width: 200px;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
}

.card:nth-child(1) {
  background-color: #ff7f50; /* Coral */
}

.card:nth-child(2) {
  background-color: #6a5acd; /* SlateBlue */
}

.card:nth-child(3) {
  background-color: #ff69b4; /* HotPink */
}

.card:nth-child(4) {
  background-color: #20b2aa; /* LightSeaGreen */
}

.card:nth-child(5) {
  background-color: #ffb6c1; /* LightPink */
}


.login-container{
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.form-container{
  width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 1px solid rgba(128, 128, 128, 0.288);
  padding-bottom: 20px;
  padding-top: 20px;
}

.form-container button{
  display: block;
}

.form-container input{
  padding: 10px;
  margin: 10px;
  width: 250px;
}

@media(max-width:900px){

  .cards{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .card{
    background-color: #ffffff;
    border-radius: 10px;
    padding: 10px;
    margin: 5px;
    text-decoration: none;
    color: #000;
    transition: all 0.3s ease;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    width: 130px;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}