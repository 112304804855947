.text-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .about-section textarea {
    width: 100%;
    height: 150px;
    margin-bottom: 10px;
  }
  
  .contact-section input {
    width: 100%;
    margin-bottom: 10px;
  }
  
  .edit-buttons button {
    margin-right: 10px;
  }
  